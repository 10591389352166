<template>
  <div>
    <p v-if="option_data">
      상품옵션:<span>{{ option_data_titlie }}</span>
      <select
        name="option_select"
        ref="option_select"
        v-for="data in option_data"
        :key="data.title"
        @change="option_select"
      >
        <option value="">- {{ data.title }}선택 -</option>
        <option v-for="opt in data.options" :key="opt.opt" :value="opt.opt">
          <span v-if="opt.chk_stock">
            {{ decodeHtmlEntity(opt.opt_string) }}</span
          >
          <span v-else>품절</span>
        </option>
      </select>
    </p>
    <p v-if="suboptions">
      추가구성:<span>{{ suboptions_title }}</span>
      <select
        name="suboption_select"
        ref="suboption_select"
        @change="suboption_select"
      >
        <option value="" v-if="sub_required">- 선택안함 (필수) -</option>
        <option value="" v-else>- 선택안함 -</option>
        <option
          v-for="data in suboptions[0]"
          :key="data.suboption_seq"
          :value="data.suboption"
        >
          <span v-if="data.chk_stock">
            {{ data.suboption }}
            <span v-if="data.price > 0">
              {{ won(data.price) }}
            </span>
          </span>
          <span v-else>품절</span>
        </option>
      </select>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    option_data: {
      type: Array,
      default: () => [],
    },
    suboptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    option_data_titlie() {
      if (this.option_data && this.option_data[0]) {
        return this.option_data[0].title;
      }
      return "";
    },
    sub_required() {
      if (this.suboptions && this.suboptions[0]) {
        if (this.suboptions[0][0].sub_required.toLowerCase() === "y") {
          return true;
        }
      }
      return false;
    },
    suboptions_title() {
      if (this.suboptions && this.suboptions[0]) {
        return this.suboptions[0][0].suboption_title;
      } else {
        return "";
      }
    },
  },
  methods: {
    decodeHtmlEntity(str) {
      return this.$decodeHtmlEntity(str);
    },
    won(str) {
      return `+${this.$won(str)}원`;
    },
    option_select() {
      const step = "main";
      const option = this.$refs.option_select.value;
      console.log(option);
      this.$emit("set_option", {
        option,
        step,
        sub_required: this.sub_required,
      });
    },
    suboption_select() {
      const step = "sub";
      const suboption = this.$refs.suboption_select.value;
      console.log(suboption);
      this.$emit("set_option", {
        option: suboption,
        step,
        sub_required: this.sub_required,
      });
    },
  },
};
</script>

<style></style>

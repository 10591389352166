<template>
  <Goods />
</template>

<script>
import { mapState } from "vuex";
import Goods from "@/components/shop/Goods";
export default {
  components: {
    Goods,
  },
  computed: {
    ...mapState("shop", {
      goods: "goodsInfo",
      assign: "assign",
      options: "options",
      msg: "msg",
      result: "result",
    }),
  },
  metaInfo() {
    return {
      title: `${this.goods.goods_name} - ${process.env.VUE_APP_APP_NAME}`,
      meta: [
        {
          vmid: "title",
          name: "title",
          content: `${this.goods.goods_name} - ${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "author",
          name: "author",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "description",
          name: "description",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: `${this.goods.keyword}`,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${process.env.VUE_APP_API_URL}${this.$route.fullPath}`,
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: `${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: `${this.goods.goods_name} - ${process.env.VUE_APP_APP_NAME}`,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: `${this.goods.summary}`,
        },
        { vmid: "og:type", property: "og:type", content: "website" },
        {
          vmid: "og:image",
          property: "og:image",
          content: `${process.env.VUE_APP_API_URL}/${this.assign.APP_IMG}`,
        },
      ],
    };
  },
};
</script>

<style></style>

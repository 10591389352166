<template>
  <div class="option-box">
    <p>
      <span>{{ option.option_title }} : {{ option.title }}</span>
      <span>수량:<input type="number" min="1" max="100" :value="1" /></span>
      <span>{{ won(option.price) }}원</span>
      <span><button @click="delete_item()">삭제</button></span>
    </p>
    <p v-for="sub in option.sub_option" :key="sub.title">
      <span>{{ sub.option_title }} : {{ sub.title }}</span>
      <span>수량:<input type="number" min="1" max="100" :value="1" /></span>
      <span>{{ won(sub.price) }}원</span>
      <span><button @click="delete_item(sub.title)">삭제</button></span>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: null,
    },
  },
  methods: {
    delete_item(title = null) {
      if (!title) {
        const type = this.option.suboption ? "sub" : "main";
        this.$emit("delete", { title: this.option.title, type });
      } else {
        this.$emit("delete", { title, type: "option_sub", index: this.index });
      }
    },
    won(str) {
      return this.$won(str);
    },
  },
};
</script>

<style lang="scss" scoped>
.option-box {
  p {
    width: 100%;
    font-size: 12px;
    padding: 5px 5px;
    border: 1px solid #eee;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  span {
    &:first-child {
      text-align: left;
      width: 40%;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:last-child {
    }
    input {
      width: 50px;
      margin: 0px 5px;
    }
  }
}
</style>

<template>
  <div class="wrap">
    <h1>상품상세</h1>
    <p>짧은 설명 : {{ goods.summary }}</p>
    <div class="content">
      <GoodsImg :images="images" />
      <div class="desc">
        <p>상품번호 : {{ goods.goods_seq }}</p>
        <p>상품명: {{ goods.goods_name }}</p>
        <p>
          판매가:
          {{ won(goods.org_price) }} / 할인가:{{ won(goods.sale_price) }}
        </p>
        <p v-for="shipped in shipping" :key="shipped.shipping_set_seq">
          {{ shipped.shipping_set_name }}
          {{
            shipped.shipping_set_code != "direct_store"
              ? shipped.prepay_txt
              : ""
          }}
          기본:{{ shipped.delivery_std_input }} 추가:{{
            shipped.delivery_add_input
          }}
          <span>환불:{{ won(shipped.refund_shiping_cost) }}</span>
          <span>교환:{{ won(shipped.swap_shiping_cost) }}</span>
        </p>
        <GoodsOptions
          v-if="option_data || suboptions"
          :option_data="option_data"
          :suboptions="suboptions"
          @set_option="option_set"
        />
        <OptionsView
          v-for="(option, index) in options"
          :key="option.title"
          :option="option"
          :index="index"
          @delete="option_delete"
        />
        <OptionsView
          v-for="sub in sub_options"
          :key="sub.title"
          :option="sub"
          @delete="option_delete"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GoodsImg from "@/components/shop/goods/GoodsImg";
import GoodsOptions from "@/components/shop/goods/GoodsOptions";
import OptionsView from "@/components/shop/goods/OptionsView";
import { mapState } from "vuex";
export default {
  data() {
    return {
      tmp_options: [],
      tmp_suboptions: [],
    };
  },
  async mounted() {
    await this.$store.dispatch("shop/getGoods", this.$route.params.seq);
    if (this.result === false) {
      alert(this.msg);
      this.$router.push("/");
    }
  },
  components: { GoodsImg, GoodsOptions, OptionsView },
  computed: {
    ...mapState("shop", {
      goods: "goodsInfo",
      assign: "assign",
      option_data: "option_data",
      msg: "msg",
      result: "result",
      suboptions: "suboptions",
    }),
    options() {
      return this.tmp_options;
    },
    sub_options() {
      return this.tmp_suboptions;
    },
    images() {
      return this.assign.images;
    },
    shipping() {
      return this.assign.shipping_set;
    },
  },
  methods: {
    won(cost) {
      return this.$won(cost);
    },
    option_set({ option: value, step, sub_required }) {
      let select_option = 0;
      if (!value) {
        return false;
      }
      if (step.toLowerCase() === "main") {
        select_option = this.option_data[0].options.find(
          (option) => option.opt === value
        );

        if (!this.tmp_options.some((item) => item.title === value)) {
          const temp = {
            option_title: this.option_data[0].title,
            price: select_option.price,
            title: value,
            option1: select_option.option1,
            option2: select_option.option2,
            option3: select_option.option3,
            option4: select_option.option4,
            option5: select_option.option5,
            sub_option: [],
          };
          this.tmp_options.push(temp);
        } else {
          alert("동일한 옵션이 있습니다.");
        }
      } else {
        if (this.option_data && this.tmp_options.length <= 0) {
          alert("옵션을 선택해 주세요.");
          return false;
        }
        select_option = this.suboptions[0].find(
          (option) => option.suboption === value
        );
        const temp = {
          option_title: select_option.suboption_title,
          price: select_option.price,
          title: value,
          suboption: select_option.suboption,
        };
        if (this.option_data) {
          if (
            !this.tmp_options[this.tmp_options.length - 1].sub_option.some(
              (item) => item.title === value
            )
          ) {
            this.tmp_options[this.tmp_options.length - 1].sub_option.push(temp);
          } else {
            alert("동일한 옵션이 있습니다.");
          }
        } else {
          if (!this.tmp_suboptions.some((item) => item.title === value)) {
            this.tmp_suboptions.push(temp);
          } else {
            alert("동일한 옵션이 있습니다.");
          }
        }
      }
      console.log("tmp_options", this.tmp_options);
      console.log("tmp_suboption", this.tmp_suboptions);
    },
    option_delete({ title, type, index }) {
      if (type.toLowerCase() === "main") {
        const idx = this.tmp_options.findIndex((item) => item.title === title);
        this.tmp_options.splice(idx, 1);
      } else if (type.toLowerCase() === "option_sub") {
        const idx = this.tmp_options[index].sub_option.findIndex(
          (item) => item.title === title
        );
        this.tmp_options[index].sub_option.splice(idx, 1);
      } else {
        const idx = this.tmp_suboptions.findIndex(
          (item) => item.title === title
        );
        this.tmp_suboptions.splice(idx, 1);
      }
      // console.log(title, type);
      console.log("tmp_options", this.tmp_options);
      console.log("tmp_suboption", this.tmp_suboptions);
    },
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
.wrap {
  width: 100%;
  height: 100%;
  .content {
    width: 800px;
    height: 300px;
    display: flex;
    .images {
      border: 1px solid #eee;
      width: 40%;
      height: 100%;
    }
    .desc {
      margin-left: 6px;
      width: 60%;
      height: 100%;
    }
  }
}
</style>
